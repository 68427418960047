<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">服务人员管理</div>

			<div class="mt20">
				<div class="form-search-box ">
					<a-form layout="inline">
						<a-form-item label="服务人员姓名">
							<a-input v-model="search.name" placeholder="请输入服务人员姓名"></a-input>
						</a-form-item>

						<a-form-item label="服务人员手机号">
							<a-input v-model="search.mobile" placeholder="请输入服务人员手机号"></a-input>
						</a-form-item>
						<a-form-item>
							<a-button @click="searchAct" type="primary">查询</a-button>
							<a-button @click="cancelAct" class="ml10">取消</a-button>
						</a-form-item>
					</a-form>
				</div>
			</div>

			<div class="mt20">
				<div class="pd30 bg-w " style="min-height: 800px;">
					<div>
						<a-button type="primary" icon="plus" @click="addServicemanAct()">添加服务人员</a-button>
					</div>

					<div class="mt20 ">
						<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
							<a-radio-button :value="1">
								在职
							</a-radio-button>
							<a-radio-button :value="-1">
								离职
							</a-radio-button>
						</a-radio-group>
					</div>

					<div class=" mt20">
						<div class="flex wrap" v-if="datas.length>0">
							<div class="appointment-serviceman-item" v-for="(item,index) in datas">
								<div class="appointment-serviceman-item-header">
									<div class="flex space">
										<div class="flex alcenter">
											<img :src="item.face" class="appointment-serviceman-item-face"/>
											<div class="ml16">
												<div class="flex alcenter">
													<div class="ft16 ftw600 cl-main text-over">{{item.name}}</div>
													<div class="appointment-serviceman-item-tag-status-on text-over ml4" v-if="item.status==1">{{item.status_means}}</div>
													<div class="appointment-serviceman-item-tag-status-off text-over ml4" v-if="item.status==-1">{{item.status_means}}</div>

												</div>
												<div class="mt16 flex alcenter">
													<i class="iconfont iconcall_gray cl-notice ft12"/>
													<div class="ft12 ftw400 cl-info ml4 text-over">{{item.mobile}}</div>
												</div>

												<div class="mt16 flex alcenter">
													<i class="iconfont icongongpai cl-notice ft14"/>
													<div class="ft12 ftw400 cl-info ml4 text-over">{{item.job_number}}</div>
												</div>
											</div>
										</div>

										<div>
											<a-dropdown placement="bottomRight">
												<span class="more-act">
													<i class="iconfont iconmore_gray"></i>
												</span>
												<a-menu slot="overlay" >
													<a-menu-item>
														<a class="menu-act" href="javascript:;" @click="getDetailAct(index)">
															<i class="iconfont ft14 iconsee"></i>
															<span class="ml10">查看详情</span>
														</a>
													</a-menu-item>
													  <a-menu-item v-if="item.status==1">
														<a class="menu-act" href="javascript:;" @click="editServicemanAct(index)">
															<i class="iconfont ft14 iconedit"></i>
															<span class="ml10">编辑服务人员</span>
														</a>
													  </a-menu-item>
													  <a-menu-item  v-if="item.status==1" @click="quitAct(index)">
														<a class="menu-act" href="javascript:;">
															<i class="iconfont ft14 iconleave"></i>
															<span class="ml10">设为离职</span>
														</a>
													  </a-menu-item>
													  <a-menu-item  v-if="item.status==-1" @click="delAct(index)">
															<a class="menu-act" href="javascript:;" >
																<i class="iconfont ft14 icondelete"></i>
																<span class="ml10">删除账号</span>
															</a>
													  </a-menu-item>
												</a-menu>
											  </a-dropdown>
										</div>
									</div>
								</div>

							</div>
						</div>

						<div class="flex center" v-else>
							<a-empty/>
						</div>

						<div class="flex center mt20">
							<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
						</div>

						<div v-if="editServicemanVisible">
							<edit-serviceman :serviceman_id="serviceman_id" :visible="editServicemanVisible" @cancel="cancelEditServiceman" @ok="okEditServiceman"></edit-serviceman>
						</div>
					</div>
				</div>

			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import EditServiceman from './components/serviceman/modal/EditServiceman.vue';
	export default {
		mixins: [listMixin],
		components:{
			EditServiceman,
		},
		data() {
			return {
				loading: false,
				editServicemanVisible:false,
				pagination: {
					current: 1,
					pageSize: 8, //每页中显示10条数据
					total: 0,
				},
				serviceman_id:0,
				search: {
					name: '',
					mobile: '',
					status: 1,
				},
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getServicemanList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
					mobile:this.search.mobile,
					status:this.search.status,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			quitAct(index){
				this.$confirm({
					title:'确认这个服务人员离职吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/quitServiceman',{
								serviceman_id:this.datas[index].serviceman_id,
							}).then(res=>{
								this.$message.success('离职成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			delAct(index){
				this.$confirm({
					title:'确认删除这个服务人员吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delServiceman',{
								serviceman_id:this.datas[index].serviceman_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			//编辑服务人员
			addServicemanAct(){
				this.serviceman_id=0;
				this.editServicemanVisible=true;
			},
			editServicemanAct(index){
				this.serviceman_id=this.datas[index].serviceman_id;
				this.editServicemanVisible=true;
			},
			cancelEditServiceman(){
				this.editServicemanVisible=false;
			},
			okEditServiceman(){
				this.editServicemanVisible=false;
				this.getLists();
			},

			//获取服务人员详情
			getDetailAct(index){
				this.$router.push('/appointment/servicemanDetail?id='+this.datas[index].serviceman_id)
			},

			changeStatus() {
				this.pagination.current = 1;
				this.getLists();
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>

	@media screen and (min-width:1901px){
		.appointment-serviceman-item{
			width: 24%;
			margin-right: 1%;
			margin-bottom: 1%;
			background: #FFFFFF;
			border: 1px solid #EBEDF5;
			padding: 20px;
			line-height: 1;
		}
	}

	@media screen and (min-width: 1551px) and (max-width: 1900px) {
		.appointment-serviceman-item{
			width: 32%;
			margin-right: 1%;
			margin-bottom: 1%;
			background: #FFFFFF;
			border: 1px solid #EBEDF5;
			padding: 20px;
			line-height: 1;
		}
	}

	@media screen and (min-width: 1161px) and (max-width: 1550px) {
		.appointment-serviceman-item{
			width: 49%;
			margin-right: 1%;
			margin-bottom: 1%;
			background: #FFFFFF;
			border: 1px solid #EBEDF5;
			padding: 20px;
			line-height: 1;
		}
	}

	@media screen and (max-width: 1160px) {
		.appointment-serviceman-item{
			width: 99%;
			margin-right: 1%;
			margin-bottom: 1%;
			background: #FFFFFF;
			border: 1px solid #EBEDF5;
			padding: 20px;
			line-height: 1;
		}
	}


	.appointment-serviceman-item-header{
	}

	.appointment-serviceman-item-face{
		width: 100px;
		height: 100px;
	}

	.appointment-serviceman-item-tag-status-on{
		border-radius: 2px;
		border: 1px solid #4772FF;
		padding: 2px 6px;
		font-size: 12px;
		font-weight: 400;
		color: #4772FF;
	}

	.appointment-serviceman-item-tag-status-off{
		border-radius: 2px;
		border: 1px solid #A6AEC2;
		padding: 2px 6px;
		font-size: 12px;
		font-weight: 400;
		color: #A6AEC2;
	}

	.appointment-serviceman-item-tag-year{
		background: rgba(242, 130, 40, 0.1);
		border-radius: 2px;
		border: 1px solid rgba(177, 117, 34, 0.5);
		padding: 2px 6px;
		font-size: 12px;
		font-weight: 400;
		color: #B17522;
	}

	.appointment-serviceman-item-content{
		border-bottom: 1px solid #EBEDF5;
		padding: 16px 0px;
	}
</style>
